<template>
  <v-card class="elevation-1">
    <v-card-title>
      Members For Activation
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="3" lg="3">
          <v-combobox
            v-model="membershipType"
            :items="['CPP', 'MLM']"
            label="Membership Type"
            outlined
            dense
          ></v-combobox>
        </v-col>
        <v-col cols="12" md="4" lg="4" offset-md="5" offset-lg="5">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      dense
    >
      <template #[`item.action`]="{ item }">
        <v-btn
          x-small
          text
          color="error"
          @click="activate(item)"
        >
          Activate
        </v-btn>
        <v-btn
          x-small
          text
          color="error"
          @click="confirmCancel(item)"
        >
          Cancel Request
        </v-btn>
      </template>
    </v-data-table>

    <activate-member-dialog
      v-if="showDialog"
      :showDialog="showDialog"
      :data="selectedItem"
      @closeDialog="showDialog=false"
      @updateTable="updateTable"
    ></activate-member-dialog>

    <activate-cpp-dialog
      v-if="showCppDialog"
      :showDialog="showCppDialog"
      :data="selectedItem"
      @closeDialog="showCppDialog=false"
      @updateTable="updateTable"
    ></activate-cpp-dialog>
    
    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    />

    <confirm-password
      :show="showConfirmCancelDialog"
      @cancel="showConfirmCancelDialog=false"
      @execute="cancel"
    >
    </confirm-password>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import getData from '@/composables/getData'
import snackbar from '@/components/SnackBar.vue'
import ActivateMemberDialog from './ActivateMember.vue'
import ActivateCppDialog from './ActivateCpp.vue'
import ConfirmPassword from '@/components/ConfirmPassword.vue'
import crud from '@/composables/crud'

export default {
  components: {
    snackbar,
    ActivateMemberDialog,
    ConfirmPassword,
    ActivateCppDialog
  },

  setup(props, { emit }) {
    const formErrorFields = ref({})
    const cancellingRequest = ref(false)
    const showConfirmCancelDialog = ref(false)
    const showDialog = ref(false)
    const showCppDialog = ref(false)
    const selectedItem = ref('')
    const search = ref('')
    const headers = ref([
      { text: 'Name', align: 'start', value: 'member_name' },
      { text: 'Mobile Number', align: 'start', value: 'mobile_number' },
      { text: 'Email', align: 'start', value: 'email' },
      { text: 'Province', align: 'start', value: 'province_name' },
      { text: 'City', align: 'start', value: 'municipality_name' },
      { text: 'Barangay', align: 'start', value: 'barangay_name' },
      { text: 'Address', align: 'start', value: 'address' },
      { text: 'Date Registered', align: 'start', value: 'created_at' },
      { text: 'Sponsor', align: 'start', value: 'sponsor' },
      { text: 'Action', align: 'middle', value: 'action' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)
    const uniqueId = ref('')
    const membershipType = ref('MLM')

    const listUri = `${process.env.VUE_APP_URI}/api/stockist/members`
    const { store, responseMessageStatus, responseMessage } = crud()

    watch(() => membershipType.value, val => {
      options.value.page = 1
      getData(listUri, items, totalItems, loading, options, search, val)
    })

    watch(() => uniqueId.value, () => {
      getData(listUri, items, totalItems, loading, options, search, membershipType.value)
    })

    watch(options, () => {
      getData(listUri, items, totalItems, loading, options, search, membershipType.value)
    })

    watch(searchTable, () => {
      options.value.page = 1
      getData(listUri, items, totalItems, loading, options, search, membershipType.value)
    })

    const activate = async item => {
      if(membershipType.value === 'MLM') {
        showDialog.value = true
      } else {
        showCppDialog.value = true
      }

      selectedItem.value = item
    }

    const updateTable = () => {
      uniqueId.value = Math.random()
    }

    const confirmCancel = item => {
      selectedItem.value = item
      showConfirmCancelDialog.value = true
    }

    const cancel = async () => {
      await store(`${process.env.VUE_APP_URI}/api/stockist/members/cancel-request`, cancellingRequest, { 
        id: selectedItem.value.id,
        membership_type: membershipType.value,
      }, formErrorFields)

      if (responseMessageStatus.value === 'success') {
        updateTable()
        showConfirmCancelDialog.value = false
      }
    }

    return {
      showDialog,
      showCppDialog,
      search,
      headers,
      items,
      searchTable,
      options,
      totalItems,
      loading,
      activate,
      selectedItem,
      updateTable,
      membershipType,

      responseMessageStatus,
      responseMessage,

      cancellingRequest,
      showConfirmCancelDialog,
      confirmCancel,
      cancel,
    }
  },
}
</script>
